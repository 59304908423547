//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Messaggio from "./messaggio_chat_desktop";
export default {
  components: {
    Messaggio,
  },
  props: {
    chat: Object,
    show: Boolean,
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        setTimeout(() => {
          this.scroll_down();
        }, 200);

        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
    show: function () {
      if (this.show) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  data() {
    return {
      dialog: false,
      text_tosend: "",
    };
  },
  computed: {
    miaConversazione() {
      return this.$store.getters.get_utente.iva == this.chat.autore.iva;
    },
  },
  methods: {
    send_msg() {
      if (this.text_tosend == "") {
        return;
      }
      this.$store
        .dispatch("send_msg", {
          id_conversazione: this.chat.id,
          body: this.text_tosend,
        })
        .then(() => {
          this.scroll_down();
        });
      this.text_tosend = "";
    },
    scroll_down() {
      this.$refs.chat_body.scroll({ top: 9999 });
    },
  },
};
