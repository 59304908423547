//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import axios from "axios";
export default {
  props: {
    ordine: Object,
    show: Boolean
  },
  watch:{
    show: function(){
      if(this.show){
        this.dialog = true
      }
    },
    dialog: function(){
      if (!this.dialog){
        this.$emit("close")
      }
    }
  },
  data() {
    return {
      new_msg: "",
      object: "",
      dialog: false,
      destinatario: null,
      valid: true,
      rules: {
        req: (v) => !!v || "Campo vuoto",
      },
      loading: false
    };
  },
  mounted() {
  },
  computed: {
    destinatari(){
      return this.$store.getters.get_destinatari
    },
    utente() {
      return this.$store.getters.get_utente;
    },
  },
  methods: {
    send_new() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true
      let n_ordine = this.ordine ? this.ordine.numero.toString() : null
      this.$store
        .dispatch("new_conversation", {
          rif_ordine: n_ordine,
          destinatario: this.destinatario,
          titolo: this.object,
          msg: this.new_msg,
        })
        .then(() => {
          this.$refs.form.reset();
          this.dialog = false;
        })
        .finally(()=>{
            this.loading = false
        })
    },
  },
};
