//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messaggi_mixin from "../mixin/messaggi_mixin";
import MessaggioElement from "./messaggi_element_desktop";
import NuovaConversazione from "./nuova_conversazione_desktop";
export default {
  components: {
    MessaggioElement,
    NuovaConversazione,
  },
  mixins: [messaggi_mixin],
};
