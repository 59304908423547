//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messaggio_element_mixin from "../mixin/messaggio_element_mixin";
import DettaglioOrdine from "@/components/ordini/desktop/ordine_dettaglio_desktop.vue";
import Chat from "./chat_dettaglio_desktop";
export default {
  components: {
    DettaglioOrdine,
    Chat,
  },
  mixins: [messaggio_element_mixin],
};
